ion-modal.modal-classic {
  --width: 100%;
  --height: 100%;
}

ion-modal.modal-classic .modal-classic-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

ion-modal.modal-classic .modal-classic-content {
  flex: 1 1 auto;
  overflow-y: auto;
  margin: 35px 0 0;
  padding: var(--safe-area-inset-top, 12px) var(--ion-padding) var(--ion-padding) var(--ion-padding);
}

ion-modal.modal-classic .modal-classic-closer {
  position: absolute;
  top: var(--safe-area-inset-top);
  right: var(--ion-padding);
  cursor: pointer;
  z-index: 5;
  margin-top: 10px;
}
ion-modal.modal-classic .modal-classic-closer ion-icon {
  height: 25px;
  width: 25px;
}
ion-modal.modal-classic .modal-classic-header,
ion-modal.modal-classic .modal-classic-action {
  flex: 0 1 auto;
}

ion-modal.modal-classic .modal-classic-header {
  padding: calc(var(--safe-area-inset-top) + 10px) 5px;
}

ion-modal.modal-classic .modal-classic-header h3 {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

ion-modal.modal-classic.modal-with-color-header .modal-classic-header h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
}

ion-modal.modal-classic.modal-with-color-header .modal-classic-wrapper::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: calc(var(--safe-area-inset-top) + 42px);
  background-color: var(--ion-color-primary);
  z-index: -1;
}

ion-modal.modal-classic.modal-with-color-header .modal-classic-closer {
  font-size: 20px;
}

.modal-classic-wrapper {
  padding: var(--ion-padding) !important;
  overflow: auto;
}
.web .modal-classic-wrapper {
  padding-right: calc(var(--ion-padding) + 10px) !important;
}
.modal-classic-content {
  padding: 0 !important;
}
.web ion-modal.modal-classic {
  --height: 85%;
  --width: var(--okx-bsmodal-width-lg);
  --border-radius: 10px;
}
