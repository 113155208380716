.web ion-content.route-pick-up-point-check {
  --ion-color-base: transparent;
  --background: transparent url(../../assets/images/drop-point-bg.jpg) repeat-y 0 0 / 100% auto;
}

.code-checking-box {
  min-height: 80px;
}

.route-pick-up-point-check .restaurant-card {
  position: absolute;
  top: 0;
  border-radius: 0;
}

.route-pick-up-point-check .restaurant-card > ion-card-content {
  height: 100%;
}

#main > ion-content.route-pick-up-point-check {
  z-index: 10;
}

.web #main > ion-content.route-pick-up-point-check {
  z-index: 5;
}

.pup-modal-inner-wrapper {
  height: 100%;
}

.pup-modal-info-holder {
  height: var(--okx-pup-map-wrapper-top);
  overflow-y: auto;
  text-align: center;
}

/* ion-modal.modal-classic.pup-modal.check-modal .modal-classic-content {
	flex: 0 1 44vh;
} */

.web .route-pick-up-point-check .delivery-option-label {
  display: none;
}
.route-pick-up-point ion-label {
  width: 0;
}
.web .pick-up-point-check-bg {
  background-position: 100% 0;
  background-repeat: repeat-y;
}
.code-checking-box .box-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.code-checking-box .box-wrapper > div {
  margin-left: 10px;
  flex: 1;
}
