.history-order-bar,
.history-order-bar-scrim {
  z-index: 5;
}

.history-order-bar {
  left: 10%;
  right: 10%;
  /* top: 27%; */
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  background: var(--ion-color-primary-contrast) url(../../assets/images/repeat.svg) no-repeat 10px
    50%;
  background-size: 15px 15px;
}
.dashboard-bars-wrapper .history-order-bar {
  background: var(--ion-color-primary-contrast) url(../../assets/images/repeat.svg) no-repeat 10px
    50%;
  background-size: 15px;
  margin: 10px var(--ion-padding) 0;
}
.history-order-bar.warning {
  background-image: url(../../assets/images/warning-white.svg);
}

.history-order-bar.error {
  background-image: url(../../assets/images/error-white.svg);
}

.history-order-bar-type {
  font-weight: bold;
}

.history-order-bar-type,
.history-order-bar-body {
  color: var(--ion-color-primary);
  font-weight: bold;
}
