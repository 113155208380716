.okx-validate-input-wrapper {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
}

.okx-validate-input-wrapper .okx-validate-input-holder {
  flex: 1 1 auto;
}
.okx-validate-input-wrapper .okx-validate-input-holder .normal-text {
  color: var(--okx-box-wrapper-color) !important;
}
.okx-validate-input-wrapper .okx-validate-input-holder ion-datetime {
  --padding-start: 0;
  --padding-top: 15px;
}
/* .okx-validate-input-wrapper .okx-validate-input-holder p {
  font-size: 13px;
  font-weight: 500;
  margin: 21px 0 0 0;
  display: flex;
  padding-left: 2px;
  color: inherit;
} */

.okx-validate-input-wrapper ion-button {
  height: 30px !important;
  width: 30px !important;
  margin-top: 0;
}

.date-picker-wrapper.modal .data-picker-input {
  padding-top: 15px;
}

.date-picker-wrapper.modal ion-label {
  font-size: 8px;
}
.okx-validate-input-wrapper .okx-validate-input-holder ion-label {
  font-size: var(--okx-small-text-font-size);
}
.date-picker-modal-label {
  font-size: var(--okx-small-text-font-size);
  color: var(--ion-color-dark);
  margin-left: 2px;
  transition: 0.1s ease-in;
}
