ion-content.route-vouchers {
  background-image: url(../../assets/images/vouchers-bg.jpg);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
}

.voucher-bar {
  display: flex;
  align-items: center;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.voucher-bar > div:first-child,
.voucher-bar > div:last-child {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.voucher-bar > div:first-child {
  padding-left: 16px;
}

.voucher-bar > div:last-child {
  text-align: right;
  padding-right: 12px;
}

.voucher-code {
  width: 100%;
}

.voucher-bar ion-button {
  margin-bottom: 4px;
  --border-width: 1px;
  --border-color: rgba(var(--ion-color-gray-rgb), 0.5);
  color: var(--ion-color-secondary);
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 3px;
}

.voucher-bar p {
  font-size: var(--okx-small-text-font-size);
}

.voucher-intro-modal > .modal-wrapper {
  --max-height: 450px;
  width: 300px;
  border-radius: 15px;
}

.web .voucher-intro-modal > .modal-wrapper {
  height: 80%;
  min-height: 400px;
  width: 500px;
  border-radius: 15px;
}

.web .voucher-header ion-toolbar.dynamic-header {
  top: 0px;
  position: unset;
}

.voucher-info-wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto min-content;
  height: 100%;
}

.voucher-info-wrapper .voucher-intro-modal-content {
  overflow-y: auto;
}

.voucher-intro-modal .voucher-header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-primary);
}

.voucher-info-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 185px;
  margin-bottom: 20px;
}

.voucher-intro-modal > .modal-wrapper > .ion-page ion-button {
  padding: 0 20px;
}

.voucher-intro-modal > .modal-wrapper > .ion-page ion-icon {
  position: absolute;
  right: 15px;
  font-size: 24px;
  z-index: 10;
}

.noQrCode {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.voucher-intro-modal .voucher-header > ion-toolbar > ion-title {
  width: 100%;
  color: var(--ion-color-white);
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
  padding: 0;
  padding-left: 20px;
  visibility: visible !important;
}

.voucher-intro-modal > .modal-wrapper > .ion-page ion-icon {
  position: absolute;
  right: 15px;
  font-size: 24px;
  z-index: 10;
}

.voucher-content {
  cursor: pointer;
  box-shadow: 0px 0px 10px -3px #000000;
  margin: var(--ion-padding);
  height: 200px;
  border-radius: var(--okx-box-radius);
  overflow: hidden;
  position: relative;
}
.web .voucher-content {
  margin: var(--ion-padding) 0;
}
.voucher-content img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.voucher-content > div {
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  padding: 10px;
}
.loyalty-image {
  --background: #fff;
  border-radius: 0px;
  padding-bottom: 100%;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: contain;
  box-shadow: none;
}

.voucher-intro-modal ion-toolbar.dynamic-header {
  margin-top: 0;
}
​ .modal-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.absolute-content-vocuher {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* padding-inline-start: var(--ion-padding, 5px);
	padding-inline-end: var(--ion-padding, 5px);
	padding-left: var(--ion-padding, 5px);
    padding-right: var(--ion-padding, 5px); */
  /* padding-top: var(--ion-padding, 5px);
    padding-bottom: var(--ion-padding, 5px); */
}

.voucher-content ion-card-title {
  font-size: 20px;
}

.web #main > ion-content.route-vouchers > .ion-padding .absolute-content-vocuher {
  border-radius: inherit;
  overflow: hidden;
  padding: 0;
}

.web
  #main
  > ion-content.route-vouchers
  > .ion-padding
  .absolute-content-vocuher
  .flex-min:first-of-type {
  padding: var(--ion-padding) var(--ion-padding) 0 var(--ion-padding);
}

.web #main > ion-content.route-vouchers > .ion-padding .absolute-content-vocuher > .scrollable-y {
  padding: 0 var(--ion-padding);
}

.web .clickup {
  position: absolute;
}

.web .route-vouchers .ion-padding {
  position: fixed;
  left: 50%;
  top: var(--okx-bsmodal-top);
  bottom: 80px;
  width: var(--okx-bsmodal-width-lg);
  min-height: 240px;
  margin-left: calc(var(--okx-bsmodal-width-lg) / -2);
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web ion-modal.modal-classic.voucher-intro-modal {
  --height: 30%;
}

.apply-voucher {
  margin-bottom: 20px;
  border: 2px solid var(--ion-color-secondary);
}

.web ion-modal.modal-classic.voucher-intro-modal {
  --width: 500px;
  --height: 45%;
}

.voucher-code-field {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.voucher-code-field > ion-text,
.voucher-code-field > label {
  align-self: center;
  flex: 0 0 40%;
}

.voucher-code-field > ion-text > span,
.blocked-input > ion-text > span {
  font-weight: bold;
  letter-spacing: 0.044em;
}

.voucher-code-field > ion-input,
.blocked-input > ion-input {
  --placeholder-color: var(--ion-color-gray);
  --placeholder-opacity: 1;
  text-transform: initial;
  /* margin-inline-start: 20px; */
}

.blocked-input > ion-input {
  margin-inline-start: 0;
}
