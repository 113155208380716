/* behavior */

.item-details-image {
  position: absolute;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  /* next line is portrait-only */
  height: 100vw;
  background: transparent none no-repeat 50% 0;
  background-size: cover;
}

.item-details-card-header h2,
.item-details-card-header h3,
.item-details-card-header h4 {
  margin-top: 0;
}

.item-details-actions {
  position: absolute;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.item-details-back {
  left: 16px;
  top: 16px;
}

/* end behavior */

/* content reset */

.route-item-details ion-button.item-details-add-to-order {
  margin-top: 0;
  margin-bottom: 0;
  flex: 1 1 auto;
  /*margin-left: 0;*/
  --padding-start: 8px;
  --padding-end: 8px;
  white-space: break-word;
}

/* end content reset */

/* design */

.item-details-wrapper .no-padding {
  background: var(--ion-color-light);
}

.item-details-card {
  background-color: #fff;
  border-top-left-radius: var(--ion-card-radius);
  border-top-right-radius: var(--ion-card-radius);
}

.item-details-card-header {
  padding: 10px var(--ion-padding) 10px;
  z-index: 1;
}

.item-details-card-header .title {
  font-weight: bold;
  margin: 0;
}

.item-details-card-content .sectiontitle {
  margin: 0;
  width: 100%;
}

.item-details-card-description,
.item-details-card-description p,
.item-details-card-description p span {
  color: var(--ion-color-dark) !important;
}

span.item-details-card-description {
  display: inline-block;
  margin: 5px 0 15px 0;
  font-size: 13px;
}

.item-details-card-price {
  font-size: var(--okx-subtitle-font-size);
  font-weight: bold;
  color: var(--ion-color-dark);
  margin-bottom: 10px;
}

.item-details-card-content {
  padding: 0 var(--ion-padding) 200px;
}

.item-details-actions {
  background-color: #fff;
  padding: 15px var(--ion-padding);
}

.item-details-actions ion-button {
  margin-top: 0;
  margin-bottom: 0;
}

.item-details-actions .incrementer {
  flex: 0 0 85px;
}

.item-details-actions .incrementer-decrease,
.item-details-actions .incrementer-incease {
  flex-basis: 25px;
  height: 25px;
}

.item-details-actions .incrementer-quantity-solo {
  flex-basis: 30px;
}

.item-details-actions-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-details-remove,
.item-details-add {
  flex: 0 0 40px;
  height: 40px;
  --padding-start: 0;
  --padding-end: 0;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-light);
  --background: #fff;
  --background-hover: var(--ion-color-light);
  --color: var(--ion-color-primary);
}

.item-details-quantity {
  flex: 0 0 75px;
  text-align: center;
}

.item-details-background {
  background: var(--ion-color-gray) url(../../assets/images/logo-main.png) no-repeat 50% 50%/70% 80%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  /* next line is portrait-only */
  opacity: 0.7;
  height: 100vw;
}
/*
ion-button.item-details-add-to-order.greyed {
	--background: var(--ion-color-gray-tint);
}
*/

.item-details-product-label {
  line-height: normal;
}
.nutrition-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}
.nutrition-table th {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
}
.nutrition-table td,
.nutrition-table th {
  border: 1px solid var(--ion-color-light);
  text-align: start;
  padding: 5px 3px;
  border-right: 0;
  border-left: 0;
}
.accordian-button {
  padding: 10px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}
.nutrition-accordion-wrapper {
  border-radius: var(--okx-box-radius);
}
.accordian-button span {
  margin-right: 5px;
  font-size: 14px;
}
.ingredients-text {
  margin: 0 0 5px 0;
}

/* end design */
/*
.web .okx-pullup {
	top: 120px !important;
	bottom: 50px !important;
	transform: none !important;
	left: 50%;
	right: auto;
	width: 400px;
	margin-left: -200px;
	border-radius: var(--okx-box-radius);
}

.web .notch {
	display: none;
}

.web .okx-pullup-content {
	bottom: 140px !important;
}

.web .item-details-actions {
	left: calc(50%);
	width: 350px;
	margin-left: -175px;
	bottom: 60px;
}
*/

.bundle-modifier-group {
  display: flex;
  flex-direction: column;
}
.bundle-modifier-group ion-item {
  padding-left: var(--ion-padding) !important;
}
.item-details-main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--okx-background-color);
}
.item-details-header.active {
  background-color: var(--ion-color-white);
}
.item-details-header {
  display: flex;
  padding: 30px var(--ion-padding);
  position: absolute !important;
  top: 0;
  z-index: 5;
  width: 100%;
}
.item-details-header ion-button {
  margin: 0;
  height: 40px;
  width: 40px;
  --border-radius: 50%;
}
.item-details-header .normal-text {
  margin-left: 30px;
  font-size: 18px;
}
.item-details-info .item-details-name {
  padding: 20px var(--ion-padding) 0;
}
.item-details-group-wrapper ion-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
/* .item-details-group-wrapper > ion-radio-group > div:not(.bundle-modifiers-wrapper),
.item-details-group-wrapper > div:not(.bundle-modifiers-wrapper),
.bundle-modifiers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--ion-color-white);
  border-bottom: 1px solid var(--ion-color-gray);
  padding: 0 5px 0 0;
}
.item-details-group-wrapper > ion-radio-group > div:not(.bundle-modifiers):last-of-type,
.item-details-group-wrapper > div:not(.bundle-modifiers):last-of-type {
  border: none;
}
.web .item-details-group-wrapper > ion-radio-group > div ion-item,
.item-details-group-wrapper > div ion-item {
  flex: 1 1;
  width: unset;
}
.web .item-details-group-wrapper .price-increment-content {
  padding: 0 10px;
  flex: unset;
  margin: 0 10px;
}
.item-details-group-wrapper .price-increment-content {
  flex: 1;
}
.item-details-group-wrapper {
  border: 1px solid var(--ion-color-light);
  border-radius: var(--okx-box-radius);
  overflow: hidden;
  margin-bottom: 20px;
}
.item-details-group-wrapper ion-item {
  background-color: var(--ion-color-white);
  padding: 5px 20px;
  border-bottom: 1px solid var(--ion-color-light);
}
.web .item-details-group-wrapper ion-item {
  padding: 0 10px;
}
.item-details-group-wrapper ion-item:last-of-type {
  border-bottom: 0;
} */
.item-modifier-group {
  margin-top: 20px;
}

.exclude-taxes-text {
  float: right;
  padding-right: 15px;
}
