:root {
  --clickup-initial-height: 50px;
  --clickup-open-height: 50%;
}

.pill-status-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pill-status {
  border: 1px solid var(--ion-color-tertiary);
  border-radius: var(--okx-small-text-font-size);
  font-size: var(--okx-small-text-font-size);
  padding: 3px 6px;
  margin: 5px 5px 5px 0;
  display: inline-block;
  color: white;
  background-color: var(--ion-color-tertiary);
}

.pill-status-action {
  cursor: pointer;
}
