.validate-button {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.validate-button .validate-status {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-white);
  border-radius: 15px;
  padding: 5px;
  width: 120px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  user-select: none;
}
.validate-button.valid .validate-status {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
}
.web .validate-button .validate-status {
  padding: 3px;
}
