ion-segment-button {
  text-transform: none;
  letter-spacing: initial;
}

.web ion-content.route-loyalty {
  background-image: url(../../assets/images/wallet-bg.jpg);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
}

/* second tab */

.signature {
  font-size: 32px;
}

.qr-holder {
  padding: 24px;
  background-color: #fff;
  display: block;
  margin: 0 auto 20px;
}

.loyalty-content.scan {
  padding-bottom: 60px;
}

.loyalty-content.scan h2 {
  margin-top: 20px;
  margin-left: 24px;
}

.noQrCode {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* end second tab */

/*
.points-tab::after {
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	display: block;
	content: '';
	background-image: linear-gradient(#fff 70%, rgba(255,255,255,0) 180%);
}
*/
.verified-content {
  width: 190px;
  margin: 0 auto;
}

.web .route-loyalty .tabs ion-slides .absolute-content.points-tab .scrollable-y {
  height: 100%;
}

.web .route-loyalty .tabs {
  /* position: relative; */
  height: 100%;
  border-radius: inherit;
}

.web .route-loyalty .tabs ion-slides,
ion-slide {
  height: calc(100vh - 350px);
  min-height: 350px;
}

.web ion-segment-button.segment-button-checked {
  border-bottom-color: var(--ion-color-primary);
}
