ion-button.link.link-centered {
  margin: 0 auto;
}

.web .route-checkout .ion-padding {
  position: absolute;
  left: 50%;
  top: 30px;
  bottom: 50px;
  width: 400px;
  margin-left: -200px;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-checkout .ion-padding .absolute-content {
  padding: 32px 32px 16px;
}

.paymentRequestButton {
  margin-bottom: 20px;
}

.paymentRequestButtonCardAdd {
  padding: 0 2px;
}

.custom-ion-button-margin {
  margin-top: 8px !important;
  text-decoration-line: underline;
}
