.validate-content {
  margin-top: 50px;
}

ion-item.contact-number ion-input {
  --padding-bottom: 7px;
  font-size: 14px;
}

.click-collect-content {
  margin-top: -22px;
}

.web .route-order-summary .ion-padding .absolute-content {
  padding: var(--ion-padding);
  margin-top: 0;
}

.web .route-order-summary .ion-padding .absolute-content .scrollable-y ion-grid.order-content-wrapper,
.web .route-order-summary .ion-padding .absolute-content .scrollable-y .validate-content/*,
.web .route-order-summary .ion-padding .absolute-content .flex-min*/ {
  padding: 0 var(--ion-padding);
}

.web .route-order-summary .checkout {
  margin-bottom: 20px;
}

.web .route-order-summary .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

.web .route-order-summary .ion-padding ion-button.add-items-btn,
.web .route-order-summary .ion-padding ion-button.redeem-points-btn {
  margin: 10px 0 !important;
}

.web .route-order-summary .ion-padding ion-button.redeem-points-btn.disabled,
.web .route-order .no-padding ion-button.redeem-points-btn.disabled {
  --ion-color-dark: gray;
}

.allergen-modal > .modal-wrapper > .ion-page {
  min-width: 100%;
  overflow: auto;
}

/* .web ion-modal.modal-classic.allergen-modal .modal-classic-closer {
  font-size: 20px;
  padding: 11px;
} */

.contact-number-label {
  padding-top: 3px;
}

.contact-number-btns {
  position: absolute;
  right: 2px;
  bottom: 2px;
  height: 38px;
  width: 38px;
}

.contact-number-btns ion-button {
  height: 100%;
  width: 100%;
  --border-radius: 50%;
}

.contact-number-icon {
  position: absolute;
  right: 0;
  bottom: 2px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 50%;
  padding: 7px;
  transition: 0.2s ease-in-out;
}

.web .contact-number-icon {
  bottom: 4px;
}

.web .contact-number-icon:hover {
  background: rgba(0, 0, 0, 0.13);
}

ion-button.checkout-btn.greyed {
  /* --background: var(--ion-color-gray-tint); */
  /* --background: var(--ion-color-secondary); */
  color: var(--ion-color-gray-tint);
}

ion-button.tertiary-btn-order {
  --background: var(--ion-color-tertiary);
  color: var(--ion-color-white);
  margin-top: 10px;
}

.po-number-text {
  font-size: 10px;
  font-weight: bold;
}
.order-summary-additional-buttons-wrapper {
  border-radius: var(--okx-box-radius);
  overflow: hidden;
  border: 1px solid var(--ion-color-light);
}
.order-summary-additional-buttons-wrapper > *:not(:last-child) {
  border-bottom: 2px solid var(--ion-color-light);
}
.order-summary-additional-button {
  --border-color: transparent !important;
  background-color: var(--ion-color-white);
  --border-radius: 0;
  margin: 0 !important;
}
.order-summary-additional-button * {
  color: var(--ion-color-secondary);
}
.order-summary-additional-button > ion-text {
  flex: 1;
  text-align: left;
}
.redeem-voucher-field-wrapper {
  display: flex;
  align-items: center;
  padding: 10px 0 0 0;
}
.order-summary-redeem-voucher ion-button {
  height: 45px;
}
.order-summary-additional-button-opened ion-icon {
  transform: rotate(90deg);
}
.order-summary-additional-button-opened {
  border-bottom: 0 !important;
}
.redeem-voucher-field-wrapper ion-button {
  margin: 0 0 0 5px !important;
}
.order-summary-header-wrapper {
  display: flex;
  background-color: var(--ion-color-white);
  /* border: 2px solid var(--okx-box-wrapper-border); */
  border-radius: var(--okx-box-radius);
  margin-bottom: 10px;
}
.order-summary-header-wrapper .second-heading {
  margin-top: 5px;
}
.order-summary-header-wrapper * {
  color: var(--ion-color-dark);
}
.order-summary-header-wrapper > div {
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}
.order-summary-sidebar .input-field-container {
  margin-bottom: 0;
}
.web .order-summary-header-wrapper > div {
  padding: 5px 15px;
}
.order-summary-header-wrapper > div:nth-of-type(2) {
  align-items: center;
  flex: unset;
  border-left: 2px solid var(--ion-color-light);
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.allergen-wrapper .sectiontitle,
.allergen-wrapper .subtitle {
  margin: 0 !important;
  font-weight: normal;
}
.allergen-wrapper > div:not(.pill-group) {
  padding-left: 20px;
}

.allergen-wrapper > div:not(.pill-group) > div {
  padding-top: 10px;
}
