.membership-image {
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 200px;
  background: transparent none no-repeat;
  background-size: cover;
  margin-bottom: 20px;
}

.web ion-content.route-membership {
  background-image: url(../../assets/images/membership-bg.jpg);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
  overflow: visible;
}
