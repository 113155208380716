ion-modal.modal-classic.apply-voucher-modal {
  --width: 80%;
  --height: 45%;
  --border-radius: 10px;
}

.web ion-modal.modal-classic.apply-voucher-modal {
  --width: 500px;
  --height: 45%;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-wrapper {
  padding: 0;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-header {
  background-color: var(--ion-color-secondary);
  padding-left: 20px;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-header h3 {
  color: var(--ion-color-white);
  text-transform: uppercase;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-closer {
  color: var(--ion-color-white);
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-content {
  padding: 15px 15px;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-content > .flex-row-wrapper {
  height: 100%;
}

.apply-voucher-modal.voucher-modal-content {
  padding: 0 30px;
}

.voucher-item ion-item ion-input {
  text-transform: capitalize;
  color: black;
}

ion-button.box-button-color {
  --background: var(--ion-color-gray-tint);
}

ion-button.box-button-color-tertiary {
  --background: var(--ion-color-tertiary);
}

.apply-voucher {
  margin-bottom: 20px;
  border: 2px solid var(--ion-color-secondary);
}

.web .apply-voucher {
  margin-bottom: 20px;
  border: 2px solid var(--ion-color-secondary);
}

.web .apply-voucher,
.web .apply-voucher-box {
  /* cursor: pointer;
  max-height: 300px;
  display: flex;
  flex-flow: column;
  box-shadow: 0px 0px 10px -3px #000000;
  margin: 20px; */
}

.web .apply-voucher:first-of-type,
.web .apply-voucher-box:first-of-type {
  margin-top: 10px;
}

/* .web .apply-voucher img,
.web .apply-voucher-box img {
  height: 100%;
  object-fit: contain;
} */

/* .web ion-content.route-apply-vouchers .ion-padding > div ion-button {
  margin: 10px 0 !important;
} */
.route-apply-vouchers .redeem-voucher-field-wrapper {
  display: flex;
  align-items: center;
  flex: unset;
  padding: 0;
  background-color: transparent;
}

.route-apply-vouchers .redeem-voucher-field-wrapper .input-field-container {
  flex: 1;
  margin-right: 5px;
}
.route-apply-vouchers .redeem-voucher-field-wrapper ion-button {
  margin: 0;
  margin-top: 10px;
}
.route-apply-vouchers .voucher-content {
  margin: 2px 0 10px;
}
