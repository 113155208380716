.route-my-membership ion-toolbar.primary-toolbar {
  --background: var(--ion-color-secondary) url(../../assets/images/pure_plus_more-white.png)
    no-repeat bottom 10px center/30%;
}
.route-my-membership .image-button {
  display: none;
}
.static.route-my-membership + ion-toolbar.dynamic-header {
  --ion-toolbar-background: var(--ion-color-secondary);
}

.web .static.route-my-membership + ion-toolbar.dynamic-header {
  --ion-toolbar-background: transparent;
}

.topbackground {
  background-color: var(--ion-color-secondary);
  padding: var(--ion-padding) 0;
}

.rounded-bottom {
  border-bottom-left-radius: var(--okx-box-radius);
  border-bottom-right-radius: var(--okx-box-radius);
}

.web .rounded-bottom {
  border-radius: var(--okx-box-radius);
}

.web ion-content.route-my-membership {
  background-image: url(../../assets/images/membership-bg.jpg);

  background-repeat: no-repeat;
  background-position: 100% 0;
  overflow: visible;
}

.web #main > ion-content.route-my-membership > .ion-padding {
  position: fixed;
  left: 50%;
  top: 50px;
  bottom: 50px;
  width: 450px;
  transform: translate(-50%, 0%);
  margin-left: 0;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

ion-button.checkout-btn.greyed {
  --background: var(--ion-color-gray);
  color: #fff;
}
.membership-header * {
  color: var(--ion-color-secondary-contrast) !important;
}
