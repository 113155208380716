.web ion-content.route-membership-terms {
  background-image: url(../../assets/images/membership-bg.jpg);

  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
  overflow: visible;
}

.web .route-membership-term .ion-padding > div {
  overflow-y: auto;
  max-height: 100%;
  padding-right: 8px;
}

.checklabel {
  background-color: transparent;
  color: black;
}
