.promo-generic-bar,
.promo-generic-bar-scrim {
  z-index: 5;
}

.promo-generic-bar {
  left: 10%;
  right: 10%;
  /* top: 27%; */
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  background: var(--ion-color-secondary) url(../../assets/images/info-white.svg) no-repeat 5px 50%;
  background-size: 30px 30px;
}

.promo-generic-bar.warning {
  background-image: url(../../assets/images/warning-white.svg);
}

.promo-generic-bar.error {
  background-image: url(../../assets/images/error-white.svg);
}

.promo-generic-bar-type {
  font-weight: bold;
}

.promo-generic-bar-type,
.promo-generic-bar-body {
  color: var(--ion-color-secondary-contrast);
}
