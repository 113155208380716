.history-content {
  text-align: center;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  background-color: var(--okx-background-color);
}

.history-item {
  /*text-transform: uppercase;*/
}

.history-content.order-tab .history-item-status {
  cursor: pointer;
}
.history-item-status {
  font-size: var(--okx-small-text-font-size);
  padding: 5px;
  border-bottom: 1px solid var(--ion-color-light);
}
.history-item-status:last-of-type {
  border-bottom: 0;
}
.history-item-status h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: bold;
}

.reorder-btn {
  font-size: var(--okx-small-text-font-size);
}

.history-item-status ion-col:first-child {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.history-item-status ion-col p:first-child {
  color: var(--ion-color-gray);
  font-size: 8px;
  margin-bottom: 8px;
  font-weight: bold;
}

.history-item-status ion-col p:last-child {
  color: var(--ion-color-medium);
  font-size: 9px;
  margin-top: 2px;
  margin-bottom: 10px;
}

.history-item-status ion-col:last-child p {
  text-align: right;
  font-size: 14px;
  margin-top: 25px;
  font-weight: 500;
  color: var(--ion-color-dark);
  margin: 0;
}

/* .history-item-status ion-col:last-child p::before {
  content: '▲';
  position: relative;
  left: -20px;
  color: var(--ion-color-success);
  opacity: 0.4;
  font-size: 18px;
  display: inline-block;
  transform: scaleY(0.7);
} */

/* .history-item-status ion-col:last-child p.redeem::before {
  content: '▼';
  color: var(--ion-color-primary);
  opacity: 1;
} */

.order-button-wrapper {
  float: right;
}

.order-button {
  display: flex;
  justify-content: flex-start;
}

.order-button-wrapper .order-button {
  padding: 2px 4px;
  display: inline-flex;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  border-color: var(--ion-color-black);
}

.order-button-wrapper .order-button ion-icon {
  font-size: 10px;
  margin-left: 8px;
}

.web ion-content.route-history {
  background-image: url(../../assets/images/history-bg.jpg);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
}

.web .order-button-wrapper .order-button {
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .history-item-status {
    grid-template-columns: 1fr 165px;
  }
}

.history-points {
  background-color: var(--ion-color-light);
  height: 50px;
  width: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
