ion-content div.dash-layout {
  background-image: url(../../assets/images/page-01.jpg);
  background-repeat: no-repeat;
  background-position: 0% 90%;
  background-size: 185%;
}

ion-content div.dash-layout::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100px;
  z-index: 1;
  background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.info-panel {
  position: fixed !important;
  top: 20%;
  z-index: 5;
  left: 0;
  right: 0;
  display: grid;
  gap: 4px;
  margin: auto;
  width: 90%;
}

.dash-card {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  z-index: 1;
  --background: #fff;
  border-radius: var(--ion-card-radius);
  padding-bottom: 35%;
  background-image: url(../../assets/images/dashboard-02.svg);
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 37% auto;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  margin-left: 0;
  margin-right: 0;
}

.dash-menu {
  padding: 0 48px;
}

.dash-menu ion-item {
  --color: var(--ion-color-dark);
  --background: trasparent;
  --background-hover: trasparent;
  --min-height: 32px;
}

.dash-card ion-label {
  font-weight: bold;
}

.dash-menu ion-label {
  text-align: center;
}

.web .static.route-dashboard {
  background: transparent none;
}

.web ion-content.route-dashboard div.dash-layout.absolute-content {
  background: transparent none;
}

.web ion-content div.dash-layout::before {
  background-image: none;
}

.web .dash-card {
  top: var(--okx-bsmodal-top);
  bottom: auto;
  left: auto;
  right: auto;
  position: relative;
  width: 50%;
  max-width: var(--okx-bsmodal-width-md);
  min-width: 280px;
  padding-top: 24px;
  padding-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--okx-box-radius);
  background-position: 50% 108%;
  background-size: 120px auto;
}

.web .dash-menu ion-item {
  --color: var(--ion-color-white);
  --background: var(--ion-color-black);
  margin-bottom: 20px;
  border-radius: var(--okx-small-radius);
}

.dashboard-card:last-of-type {
  margin: 0;
}
.dashboard-card {
  height: 150px;
  border-radius: var(--okx-box-radius);
  background-position: center;
  background-size: cover;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  padding: 5px 20px;
  display: flex;
  align-items: flex-end;
}
.dashboard-card::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.7) 100%);
}

.dashboard-cards-wrapper {
  padding: var(--ion-padding) calc(var(--ion-padding) * 2);
  overflow: hidden;
  margin-top: -140px;
}
.dashboard-card .subtitle {
  color: var(--ion-color-white);
  z-index: 5;
}
.dashboard-card > * {
  z-index: 5;
}
.dashboard-header {
  padding: 10px calc(var(--ion-padding) * 2) 140px calc(var(--ion-padding) * 2);

  background-color: var(--okx-dashboard-background);
  position: relative;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.dashboard-header * {
  color: var(--ion-color-white) !important;
}
.colored-header.route-dashboard ion-toolbar.primary-toolbar {
  background-color: var(--okx-dashboard-background);
}

.white-header.route-dashboard ion-toolbar.primary-toolbar {
  background-color: var(--ion-color-white);
}

ion-content.route-dashboard.route-colored:before {
  position: fixed;
  content: '';
  top: 0;
  background-color: var(--ion-color-primary);
  height: 50%;
  width: 100%;
  z-index: -1;
  border-radius: 0 0 var(--okx-box-radius) var(--okx-box-radius);
}

.order-card {
  background-image: url(../../assets/images/order-card.jpg);
}
.membership-card {
  background-image: url(../../assets/images/membership-card.jpg);
}
.vouchers-card {
  background-image: url(../../assets/images/vouchers-card.jpg);
}
