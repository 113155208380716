.apply-points-spacer {
  margin: 32px 0;
  height: 170px;
  background: transparent url(../../assets/images/icons/wallet.svg) no-repeat 50% 0 / auto 100%;
}

.web .route-apply-points .ion-padding .absolute-content {
  padding: 32px 32px 16px;
}

.web .route-apply-points .ion-padding .absolute-content .scrollable-y/*,
.web .route-apply-points .ion-padding .absolute-content .flex-min*/ {
  padding: 0 32px;
}

.web ion-modal.modal-classic.apply-points-modal .modal-classic-closer {
  font-size: 20px;
  padding: 11px;
}
