.order-content-wrapper ion-row {
  color: var(--ion-color-dark);
  /* font-weight: bold; */
}

/* .order-content-wrapper .basket-item-wrapper {
  border-bottom: 1px solid var(--okx-border-color);
} */

/* .order-content-wrapper .basket-item-wrapper:last-of-type {
  border-bottom-width: 1px;
} */

/* .web .order-summary-sidebar .order-content-wrapper .basket-item-wrapper:last-of-type {
	border-bottom-width: 0px;
} */

.web .order-summary-sidebar .order-content-wrapper .subtotal-bordered-bottom {
  border-bottom: 2px solid var(--okx-border-color);
}

.total-bordered-bottom {
  border-bottom: 2px solid var(--okx-border-color);
  text-align: start;
}

.total-bordered-top {
  border-top: 2px solid var(--okx-border-color);
}

.subtotal-bordered-bottom {
  border-bottom: 2px solid var(--okx-border-color);
  text-align: start;
}

.bordered-bottom {
  border-bottom: 2px solid var(--okx-border-color);
  text-align: start;
}

ion-col.grow {
  flex-grow: 3.5;
}

.add-items-btn {
  font-weight: bold;
  margin: 10px 0;
}

ion-modal.modal-classic.remove-apply-voucuher {
  --width: 70%;
  --height: 30%;
  --border-radius: 10px;
}

.web ion-modal.modal-classic.remove-apply-voucuher {
  --width: 500px;
  --height: 15vh;
}

.web ion-modal.modal-classic.remove-apply-voucuher .modal-classic-header {
  height: 100%;
  display: grid;
  place-content: center;
  text-transform: uppercase;
}
.box-wrapper.box-list.order-content-wrapper > * {
  padding: 5px 10px;
  border-bottom: 1px solid var(--ion-color-light);
}
.box-wrapper.box-list.order-content-wrapper > *:last-child {
  border-bottom: none;
}
.included-taxes {
  padding: 5px 10px;
}
