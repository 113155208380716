.toggle {
  display: flex;
  flex-direction: column;
}

.web ion-content.route-register {
  background-image: url(../../assets/images/register_page.jpg);
  background-repeat: no-repeat;
  background-position: 100% 0px;
  background-size: cover;
}

.web #main > ion-content.route-register {
  z-index: 5;
}

.location-select {
  width: 100%;
}

.disable-animation {
  --ripple-color: transparent;
  --background-hover: transparent;
}

.select {
  width: 100%;
}
