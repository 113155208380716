/* .web .static.route-cards {
  background-image: url(../../assets/images/page-02.jpg);
  background-repeat: no-repeat;
  background-position: 100% 0px;
  background-size: 100% auto;
}

.web ion-content.route-cards {
  background-color: #fff;
  background-image: url(../../assets/images/page-02.jpg);
  background-repeat: no-repeat;
  background-position: 100% 0px;
  background-size: 100% auto;
} */

.web .route-cards .ion-padding {
  position: absolute;
  left: 50%;
  top: 30px;
  bottom: 50px;
  width: 400px;
  margin-left: -200px;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-cards .ion-padding ion-button:last-child {
  margin-bottom: 0;
}
