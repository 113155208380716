/* ion-content div.restaurant-gradient {
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
}

.table-order-layout {
  padding: 30px !important;
}

ion-content div.restaurant-gradient::before {
  content: '';
  display: block;
  height: 80%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 85%;
  z-index: 3;
  background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}



.restaurant-card {
  border-radius: var(--ion-card-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  margin: 0;
}

.restaurant-card ion-card-content {
  padding: 30px;
  font-size: var(--ion-font-size);
}

.restaurant-card ion-card-content .restaurant-info-title {
  padding: 0 2px;
  font-weight: bold;
}

.restaurant-card ion-card-content .restaurant-info {
  padding: 15px 15px 30px;
  text-align: center;
}

.restaurant-card .flex-col-wrapper > .flex-min {
  display: flex;
  align-items: center;
}

.click-collect-pickers-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.3;
  height: 100%;
  width: 100%;
  z-index: 3;
}

.click-collect-dialog {
  position: fixed;
  margin: 0 auto;
  background-color: #fff;
  width: 70%;
  height: 50%;
  border-radius: 10px;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -45%);
  transition: all 0.3s ease-out;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 999;
}


.click-collect-dialog.show-up {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -50%);
  pointer-events: auto;
}

.click-collect-dialog-layout {
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 5px 13px;
}

.click-collect-dialog.sc-ion-modal-md {
  border-radius: var(--border-radius);
  width: var(--width);
  min-width: var(--min-width);
  max-width: var(--max-width);
  height: var(--height);
  min-height: var(--min-height);
  max-height: var(--max-height);
  border-width: var(--border-width);
  border-style: var(--border-style);
  border-color: var(--border-color);
  background: var(--background);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  overflow: var(--overflow);
  z-index: 10;
}

.click-collect-dialog-header {
  margin: 10px;
}

.click-collect-dialog-header h3 {
  font-size: 19px;
  font-weight: 700;
  margin: 0;
  text-transform: unset !important;
}

.click-collect-dialog-closer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

.click-collect-dialog-closer ion-icon {
  font-size: 20px;
}

.click-collect-dialog-content {
  flex: 1 1 auto;
  padding: 12px;
  overflow-y: auto;
}

.click-collect-dialog-action {
  flex: 0 1 auto;
}

.click-collect-dialog-action ion-button {
  height: 36px;
}

.click-collect-dialog-content ion-label .sectiontitle {
  margin: 0;
  line-height: 18px;
}

#main > ion-content.route-click-and-collect .select-picker-label,
#main > ion-content.route-click-and-collect .time-picker-label {
  color: var(--ion-color-primary);
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.054em;
}

#main > ion-content.route-click-and-collect .mbsc-select-input.mbsc-control {
  display: none;
}

.delivery-option-label {
  position: absolute;
  top: 30px;
  left: 64px;
  right: 64px;
  text-align: center;
  z-index: 3;
}



.web .restaurant-menu ion-item {
  --color: var(--ion-color-white);
  --background: var(--ion-color-black);
  margin-bottom: 20px;
  border-radius: var(--okx-small-radius);
}

.web .restaurant-card ion-card-content {
  padding: var(--ion-padding);
}

.web .restaurant-card ion-card-content .restaurant-info {
  padding: 15px 0 0;
}

.web .click-collect-dialog {
  width: var(--okx-bsmodal-width-lg);
}

.web .click-collect-dialog-layout {
  padding: 32px 64px 48px;
}

.web .mbsc-select-input.mbsc-control {
  border-color: var(--ion-color-light);
}

.web .click-collect-dialog-header h3 {
  font-size: 22px;
  text-transform: uppercase;
}

.web .mbsc-fr-popup {
  left: 50%;
}


.web #main > ion-content.route-click-and-collect .delivery-option-label {
  display: none;
}

.web #main > ion-content.route-click-and-collect .delivery-option-label.web-only {
  display: block;
  top: 64px;
}

.click-collect-description {
  font-size: var(--okx-small-text-font-size);
  font-family: secondary-regular;
  font-weight: 300;
  padding: 1px 0;
  opacity: 0.5;
}

.click-collect-small-description {
  font-size: var(--okx-small-text-font-size) !important;
}

.secondary-description {
  color: var(--ion-color-secondary);
}

.bold-description {
  font-weight: 600;
  font-size: var(--okx-sectiontitle-font-size);
  font-family: secondary-regular;
}

.click-collect-button {
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

ion-checkbox {
  margin-left: 10px;
}

.click-collect-wrapper {
  height: calc(100% - 50px);
}

.click-collect-layout {
  height: calc(100% - 80px);
}

.click-collect-page {
  height: 100% !important;
}

.web .click-collect-layout {
  position: fixed;
  height: 65vh;
  width: 450px;
  margin-left: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: var(--ion-color-white);
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .click-collect-locations,
.web .click-collect-locations ion-list {
  max-height: 52vh !important;
}

.click-collect-locations,
.click-collect-locations ion-list {
  max-height: calc(100% - 90px) !important;
  overflow-y: auto;
}

.click-collect-items {
  border-bottom: 1px solid var(--ion-color-gray);
}

.click-collect-title-description {
  font-weight: bold;
  font-size: inherit !important;
}

.item-date-picker {
  font-weight: 600;
  height: 34px;
  line-height: 34px;
}

.item-date-picker.striked {
  text-decoration: line-through;
}

.click-collect-locations .mbsc-ios.mbsc-fr-inline .mbsc-fr-c {
  border-bottom: none !important;
}

.click-collect-locations .mbsc-ios.mbsc-fr-inline .mbsc-fr-w {
  border-top: none !important;
} */

.web .route-scheduled-delivery-time .click-collect-layout {
  padding: var(--ion-padding);
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  max-height: 100% !important;
}
.web ion-content.route-scheduled-delivery-time {
  --ion-color-base: transparent;
  --background: transparent url(../../assets/images/page-05.jpg) repeat-y 0 0 / 100% auto;
}
