ion-grid.no-padding.social {
  margin-right: -10px;
}

ion-grid.no-padding.social ion-col {
  padding-top: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.square {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.square:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.content {
  position: absolute;
  display: flex;
  flex-flow: column;
  background-color: var(--ion-color-tertiary);
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.soc-icon {
  display: flex;
  border-radius: 50%;
  width: 30%;
  height: 30%;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}

.soc-label {
  display: block;
  padding: 8px 0 0 0px;
}

.web .route-social.ion-color-white {
  --background: transparent none;
  --ion-color-base: transparent;
}

.web ion-content.route-social {
  background-image: url(../../assets/images/refer-a-friend.jpg);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
}

.web .square {
  cursor: pointer;
}

.web .route-social .ion-padding .heading-wrapper,
.web .route-social .ion-padding .frm-wrapper,
.web .route-social .ion-padding .action-wrapper {
  padding: 0 32px;
}

.web .route-social .ion-padding .frm-wrapper {
  position: absolute;
  left: 32px;
  right: 32px;
  top: 135px;
  bottom: 64px;
  overflow-y: auto;
  --ion-color-success: var(--ion-color-primary);
}

.web .soc-icon {
  width: 45%;
  height: 45%;
  font-size: 45px;
}

.web .route-social .ion-padding ion-grid.social .small-text {
  font-size: 14px;
}
