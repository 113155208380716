.web .route-order ion-toolbar.primary-toolbar {
  --background: var(--ion-color-secondary);
}

.web .static.route-order + ion-toolbar.dynamic-header {
  --ion-toolbar-background: var(--ion-color-secondary);
}

.web .order-list,
.web .order-summary-sidebar {
  top: var(--ion-toolbar-min-height);
}
.web .order-list.order-list-full {
  width: 100%;
}
.web .order-list {
  width: calc(100% - var(--okx-sidebar-width) + 10px);
}
.web .order-list {
  /* position: absolute;
  left: 0;
  right: var(--okx-sidebar-width);
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  top: 0; */
}

.web .order-list.order-list-full {
  right: 0;
}

.web .order-summary-sidebar,
.web .order-summary-sidebar::before,
.web .order-summary-sidebar::after {
  left: calc(100% - var(--okx-sidebar-width));
}

.web .order-summary-sidebar {
  position: absolute;
  right: 10px;
  bottom: 0;
  background-color: #fff;
}

.web .order-summary-sidebar .add-items-row {
  display: none;
}

.web .order-summary-sidebar::before,
.web .order-summary-sidebar::after {
  content: '';
  position: fixed;
  top: -200px;
  bottom: 0;
}

.web .order-summary-sidebar::before {
  width: 20px;
  box-shadow: -13px 0px 13px rgba(0, 0, 0, 0.12);
  z-index: 5;
}

.web .order-summary-sidebar::after {
  right: 10px;
  background-color: var(--okx-background-color);
}

.web .order-summary-sidebar .absolute-content {
  top: 0;
  padding-top: 0;
}

.web .order-summary-sidebar .title {
  font-size: var(--okx-subtitle-font-size);
}

.web .order-summary-sidebar .checkout div.righted:first-of-type {
  visibility: hidden;
}

.web .order-summary-sidebar .cnc-ordering-for {
  display: inline-block;
  width: 90%;
}

.web .order-categories {
  padding: 10px 10px 0 50px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 3;
  background-color: var(--okx-background-color);
}

.web .order-categories h2 {
  padding: 0;
  font-size: var(--okx-title-font-size);
}

.web .order-categories-inner {
  flex-wrap: wrap;
}

.web .order-categories-inner ion-button {
  text-transform: none;
  margin-right: 0;
  margin-left: 0;
  font-size: var(--ion-font-size);
}

.web .order-content {
  padding: 0 10px 0 50px;
  background-color: var(--okx-background-color);
}

/* grid */

.web .order-list-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: stretch;
  align-items: stretch;
  row-gap: 15px;
  column-gap: 15px;
  padding-right: 10px;
}

.web .order-list-grid .order-list-item {
  display: block;
  width: auto;
  margin-bottom: 0;
  min-width: 0;
  background-color: #fff;
  padding: 8px;
  padding-bottom: 2px;
  /*border: 1px solid #eee;*/
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  /* height: calc(100% - 8px); */
}

.web .order-list-grid .order-list-item .item-image {
  width: 100%;
  padding-top: 100%;
  background-color: #f7f7f7;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  min-width: 0;
  min-height: 0;
  margin: 0;
  display: block;
  height: auto;
  border-radius: 4px;
}

.web .order-list-grid .order-list-item .item-bar {
  margin: 5px 0 0 0;
  display: flex;
  justify-content: space-between;
  /* padding: 0px; */
}

.web .route-order .item-bar .item-name,
.web .route-order .item-bar .item-price {
  font-size: var(--okx-product-font-size);
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.web .route-order .item-bar .item-name.ellipsis {
  white-space: normal;
  text-overflow: unset;
}

.web .route-order .item-bar .item-price {
  text-align: right;
  align-self: center;
  flex: 0 1 35%;
}

.web .route-order .item-bar .item-price > span:first-child {
  font-size: inherit;
  font-weight: normal;
}

.web .route-order .item-bar .item-price > span.original-price {
  text-decoration: line-through;
  font-size: calc(var(--okx-small-text-font-size) - 1px);
}

.web ion-modal.modal-classic .modal-classic-wrapper,
.web ion-modal.modal-classic .modal-classic-content {
  height: 100%;
}

.web ion-modal.modal-classic .modal-classic-content {
  display: flex;
  flex-direction: column;
  overflow: visible;
  margin: 0;
}

.web ion-modal.modal-classic .item-details-image {
  position: absolute;
  left: -12px;
  width: 130px;
  padding-top: 240px;
  height: auto;
  background-size: 100% auto;
}

.web ion-modal.modal-classic .item-details-content {
  height: calc(100% - 70px);
  overflow-y: auto;
  padding-bottom: 50px;
  background-color: var(--okx-background-color);
}
.web ion-modal.modal-classic .item-details-card-content {
  padding: 0 var(--ion-padding);
}
.item-details-modal .modal-classic-content,
.item-details-modal .modal-classic-wrapper {
  padding: 0 !important;
}
.web ion-modal.modal-classic .item-details-card {
  height: 100%;
  position: relative;
}

.web ion-modal.modal-classic .item-details-card {
  height: 100%;
  position: relative;
}

.web ion-modal.modal-classic .item-details-card-price {
  text-align: left;
  margin-bottom: 10px;
}

.web ion-modal.modal-classic .item-details-actions {
  height: 70px;
  padding: 0 var(--ion-padding);
}
.web ion-modal.modal-classic .item-details-modal-info > div {
  padding: 0 var(--ion-padding);
}

/* .web ion-modal.modal-classic.allergen-modal,
.web ion-modal.modal-classic.apply-points-modal {
  --width: var(--okx-sidebar-width);
  --height: 100%;
  --border-radius: 0;
  justify-content: flex-end;
} */

.web ion-modal.modal-classic.redeem-voucher-modal {
  --width: var(--okx-sidebar-width);
  --height: 100%;
  --border-radius: 0;
  justify-content: flex-end;
}

/* .web ion-modal.modal-classic.allergen-modal .modal-classic-wrapper,
.web ion-modal.modal-classic.apply-points-modal .modal-classic-wrapper {
  left: calc(100% - var(--okx-sidebar-width));
} */

/* .web ion-modal.modal-classic.allergen-modal .absolute-content,
.web ion-modal.modal-classic.apply-points-modal .absolute-content {
  padding-top: 54px;
} */

/* .web ion-modal.modal-classic.allergen-modal .rhino {
	background: transparent url(../../assets/images/rhino.svg) no-repeat 50% 100% / 180px auto;
} */

/* .web ion-modal.modal-classic.allergen-modal .title.centered {
  text-align: left;
}

.web ion-modal.modal-classic.allergen-modal .sectiontitle {
  margin: 0;
  font-weight: bold;
} */

.web ion-modal.modal-classic.apply-points-modal .incrementer-decrease,
.web ion-modal.modal-classic.apply-points-modal .incrementer-incease {
  --color: var(--ion-color-dark);
}

.web .order-sublist-holder {
  margin-bottom: 30px;
  grid-column: 1 / -1;
}

.web .order-sublist-holder:last-of-type {
  margin-bottom: 0px;
}

@media screen and (max-width: 1280px) {
  .web .order-list-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 860px) {
  .web .order-list-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 640px) {
  .web .order-list-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
.item-details-model-header {
  padding: var(--ion-padding);
  left: 0;
  background: var(--okx-background-color);
  z-index: 1;
  top: 0;
  position: absolute;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}
.item-details-model-header.active {
  transform: translateY(0);
}

.item-details-modal-info img {
  height: 200px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 20px;
}
ion-modal.modal-classic.item-details-modal .modal-classic-closer {
  background: var(--ion-color-white);
  right: 15px;
  top: 10px;
  border-radius: 2px;
  height: 35px;
  width: 35px;
  z-index: 5;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
  display: flex;
  align-items: center;
  justify-content: center;
}
ion-modal.modal-classic.item-details-modal .modal-classic-closer ion-icon {
  color: var(--ion-color-dark);
}

.web .web-only.order-list-heading {
  position: relative;
  margin-bottom: 70px;
}
.restaurant-info-banner {
  width: 370px;
  background: var(--ion-color-white);
  height: 300px;
  border-radius: var(--okx-box-radius);
  position: absolute;
  top: 20px;
  right: 50px;
  z-index: 5;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.restaurant-info-banner * {
  color: var(--ion-color-dark);
}
.table-icon {
  background-image: url('../../assets/images/table.svg');
}
.delivery-icon {
  background-image: url('../../assets/images/delivery-icon.svg');
}
.collection-icon {
  background-image: url('../../assets/images/collection-icon.svg');
}
.restaurant-info-delivery-options {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.restaurant-info-delivery-options .icons-wrapper {
  display: flex;
}
.restaurant-info-delivery-options .icons-wrapper svg {
  stroke: var(--ion-color-dark);
}
.restaurant-info-delivery-options .icons-wrapper > div {
  height: 40px;
  margin: 0 5px;
  width: 40px;
  object-fit: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.restaurant-info-delivery-options .icons-wrapper > div:last-child {
  margin-right: 0;
}
.web .web-only.order-list-heading > .order-list-restaurant-info,
.web .web-only.order-list-heading > .order-list-restaurant-image {
  display: flex;
  flex-flow: column;
  position: relative;
}
.web .web-only.order-list-heading > .order-list-restaurant-info {
  padding: 32px 50px;
}
.web .order-list-info-box,
.web .order-list-info-box .info-box-list {
  display: flex;
}
.client-logo {
  height: 70px;
  width: 120px;
  object-fit: contain;
  margin-bottom: 20px;
}
.web .order-list-info-box {
  align-items: center;
  justify-content: flex-start;
}

.web .order-list-info-box > ion-icon {
  font-size: 30px;
}

.web .order-list-info-box .info-box-list {
  flex-flow: column;
}

.web .order-list-info-box .info-box-list > ion-list {
  padding: 0;
  display: flex;
}

.web .order-list-info-box .info-box-list > ion-list > ion-button {
  height: initial;
  margin: 0 5px 0 0;
}

.web .order-list-info-box .info-box-list > ion-list > ion-button:hover {
  background: var(--ion-color-white);
}

.web .order-list-restaurant-bg {
  background-size: cover;
  background-repeat: no-repeat;
  flex: 0 1 250px;
  position: relative;
  background-image: url(../../assets/images/home-banner.jpg);
  background-position: center;
}
/* .web .order-list-restaurant-bg:before {
  content: '';
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 1) 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
} */
.web #main > ion-content.route-order > .no-padding {
  overflow: auto;
  height: 100vh;
}

.tax-message {
  color: var(--ion-text-color);
  pointer-events: none;
}
