.colorNormal {
  color: var(--ion-color-tertiary);
}

.colorDone {
  color: #44c4a1;
}

.colorError {
  color: #cf4f6f;
}

.status-container {
  width: 32px;
  height: 100%;
  position: relative;
  /* border: 1px solid green; */
}
.status-container ion-icon {
  background-color: var(--okx-background-color);
}
.vertical-line {
  border-left: 2px solid var(--ion-color-tertiary);
  height: 100%;
  margin-left: 11px;
  position: absolute;
  /* border: 1px solid red; */
  top: 0;
  left: 0;
}

.icon-div {
  position: absolute;
  height: 24px;
  background-color: white;
  font-size: 24px;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  /* margin: 0;
  padding: 0; */
  /* border: 1px solid blue; */
}

.status-time-container {
  border: 1px solid var(--ion-color-light);
  border-radius: var(--okx-box-radius);
  width: 100%;
  height: 50px;
  background: var(--ion-color-white);
  padding: 5px;
  margin: 5px 0 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.status-time-container ion-label:last-child {
  margin-left: 0px;
  width: fit-content;
  display: grid;
  align-content: center;
}

.status-time-container ion-label:first-child {
  display: grid;
  align-content: center;
}
.order-status-track {
  padding: 0;
}
