.delivery-address-bg {
  background-color: #fff;
  background-image: url(../../assets/images/page-01.jpg);
  background-repeat: no-repeat;
  background-position: 100% 24px;
  background-size: 100% auto;
}

.address-checking-box {
  min-height: 80px;
}

#main > ion-content.route-delivery-address-check {
  z-index: 10;
}

.route-delivery-address-check .restaurant-card {
  position: absolute;
  top: 0;
  border-radius: 0;
}

.route-delivery-address-check .restaurant-card > ion-card-content {
  height: 100%;
  overflow: auto;
  background-color: var(--okx-background-color);
}

.web #main > ion-content.route-delivery-address-check {
  z-index: 5;
}

.web .delivery-address-bg {
  background-image: none;
  background-color: transparent;
}

.web ion-content.route-delivery-address-check {
  --ion-color-base: transparent;
  --background: transparent url(../../assets/images/page-05.jpg) repeat-y 0 0 / 100% auto;
}

.web .route-delivery-address-check .delivery-option-label {
  display: none;
}

.web .delivery-address-bg {
  background-position: 100% 0;
  background-repeat: repeat-y;
}
.native-input.sc-ion-input-md:invalid {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.native-input.sc-ion-input-md {
  padding-left: unset;
  padding-right: unset;
  -webkit-padding-start: var(--padding-start);
  padding-inline-start: var(--padding-start);
  -webkit-padding-end: var(--padding-end);
  padding-inline-end: var(--padding-end);
}
.native-input.sc-ion-input-md {
  border-radius: var(--border-radius);
  padding-left: var(--padding-start);
  padding-right: var(--padding-end);
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-indent: inherit;
  text-overflow: inherit;
  text-transform: inherit;
  text-align: inherit;
  white-space: inherit;
  color: inherit;
  display: inline-block;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  border: 0;
  outline: none;
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.pac-logo:after {
  content: '';
  padding: 1px 1px 1px 0;
  height: 16px;
  text-align: right;
  display: block;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 120px 14px;
}
.hdpi.pac-logo:after {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png);
}
.pac-item {
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 11px;
  color: #999;
}
.pac-item:hover {
  background-color: #fafafa;
}
.pac-item-selected,
.pac-item-selected:hover {
  background-color: #ebf2fe;
}
.pac-matched {
  font-weight: 700;
}
.pac-item-query {
  font-size: 13px;
  padding-right: 3px;
  color: #000;
}
.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px;
}
.hdpi .pac-icon {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png);
}
.pac-icon-search {
  background-position: -1px -1px;
}
.pac-item-selected .pac-icon-search {
  background-position: -18px -1px;
}
.pac-icon-marker {
  background-position: -1px -161px;
}
.pac-item-selected .pac-icon-marker {
  background-position: -18px -161px;
}
.pac-placeholder {
  color: gray;
}

.custom-border > input {
  border-bottom: 1px solid lightgray !important;
  /* max-width: 35% !important; */
}
.menus-list {
  padding: 0 !important;
}
.menus-list .menu-wrapper {
  border-bottom: 1px solid var(--ion-color-light);
  padding: 10px;
}
.menus-list .menu-wrapper:last-of-type {
  border-bottom: none;
}
.menu-wrapper ion-icon {
  cursor: pointer;
}
.menu-wrapper > div:first-of-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-wrapper > .menu-times {
  margin-left: 40px;
}
.menu-day {
  display: inline-block;
  width: 35px;
}
