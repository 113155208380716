/* ion-modal.modal-classic.pup-modal {
  --width: 80%;
  --height: 80%;
} */

.pup-map-wrapper {
  position: absolute;
  left: 0;
  top: 58%;
  right: 0;
  bottom: 0;
  margin: 20px 0 0;
  min-width: 200px;
}

.pup-map-wrapper{
  width: 100%;
  height: 300px;
}
.pup-map-wrapper * {
  width: 100%;
  height: 100%;
}

.pup-map-wrapper::after {
  content: '';
  display: block;
  /*padding-top: 100%;*/
}

.pup-modal .modal-classic-wrapper {
  padding-top: 3px !important;  
}

/* ion-modal.modal-classic.pup-modal .modal-classic-content, */
.web ion-modal.modal-classic.pup-modal .modal-classic-content {
  flex: 0 1 50%;
  overflow-y: auto;
}

.web ion-modal.modal-classic.pup-modal .modal-classic-content {
  margin-top: 10px;
}

.web ion-content.route-pick-up-point {
  --ion-color-base: transparent;
  --background: transparent url(../../assets/images/drop-point-bg.jpg) repeat-y 0 0 / 100% auto;
}

@media screen and (min-height: 723px) {
  .web ion-modal.modal-classic.pup-modal .modal-classic-content {
    flex: 0 1 55%;
    overflow-y: auto;
  }
}

.route-pick-up-point ion-label {
  width: 0;
}
