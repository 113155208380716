.web ion-content.route-login {
  background-image: url(../../assets/images/login-bg.jpg);
  background-repeat: no-repeat;
  background-position: 100% 0px;
  background-size: cover;
}

@media screen and (max-height: 700px) {
  .membership-image {
    margin-bottom: 10px !important;
    max-height: unset !important;
  }
}

.login-large-text{
  font-size: var(--okx-subtitle-font-size) !important;
  margin: 0 !important;
}
ion-button.login-large-text {
  height: 60px !important;
}